import { service } from '../CONF.js'

//个人中心-线下充值记录
export function record(page, pageSize) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/UnderpayOrder/PayRecordList',{
                params: {
                    page: page,
                    pageSize: pageSize,
                },  
            })
        }))
    })
}

//会员银行卡列表
export function bank_list() {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/UserBankcard/UserBankCardList')
        }))
    })
}

//会员添加银行卡
export function add_bank(bank_num, subbranch, pay_name, bank_name, password) {
    const data = {
        bank_num: bank_num,
        subbranch: subbranch,
        pay_name: pay_name,
        bank_name: bank_name,
        password: password,
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.post(
                '/Main/UserBankcard/addBankCard', 
                data, 
                { 
                    emulateJSON: true 
                }
            )
        }))
    })
}


// 会员交易流水
export function getCapital(startTime, endTime, sourceType, page, pageSize) {
    let data = {
        start_time: startTime,
        end_time: endTime,
        source_type: sourceType,
        page: page,
        pageSize: pageSize
    }
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/PromotionLinks/capital',{
                params: data
            })
        }))
    })
}

//房间内的个人中心数据接口
export function roomPersonalCenter(lotteryType, roomId) {
    return new Promise(function(resolve, reject) {
        resolve(service().then(function(result) {
            return result.get('/Main/Account/roomPersonalCenter',{
                params: {
                    lotteryType: lotteryType,
                    roomId: roomId,
                },
            })
        }))
    })
}