<script>
    import { getCapital } from "@/api/personal_center";

    export default {
        name:'TransactionHistory',
        directives: {
            'infinite-scroll': app.Mint.InfiniteScroll,
        },
        components: {
            Header: () => import('components/base/Header'),

            'mt-header':app.Mint.Header,
            'mt-button':app.Mint.Button,
            'mt-actionsheet':app.Mint.Actionsheet,
            'mt-datetime-picker':app.Mint.DatetimePicker,
        },
        data() {
            return {
                header: {
                    mainTitle: getWord('transaction_bill'),
                },
                list: null,
                pickerValue: "",
                startTime: new Date(),
                endTime: new Date(),
                endTimeVisible: false,
                testSourcetype: [], 
                inputSourcetype: ["2", "5", "9", "3", "98", "21"],
                outputSourcetype: ["10", "99", "4"],
                ddSourcetype: ["1", "23"],
                dtClassic: ["28", "29"],
                transferRecord: ['18','19','32','33','66','67','68','69',
                                '70','71','72','73','74','75','76','77','78','79','80','81','82','83','84','85','86','87','88','89','90','91','92','93','94','95','96','97','98','99','100','101','102','103','104','105','106','107','108','109','110','111','112','113','114','115','116','117','118','119','120','121','122','123','124','125','126','127','128','129','130','131','132','133','134','135','136','137','138','139','140','141','142','143','144','145','146','147','148','149','150','151','152','153','154','155','156','157','158','159','160','161','162','163','164','165','166','167','168','169','170','171','172','173','174','175','176','177','178','179','180','181','182','183','184','185','186','187','188','189','190','191','192','193','194','195','196','197','198','199','200'],
                highFrequency:['34','35'],
                asia:['36','37'],
                sourceType: { id: null, method: this.getresult, name: getWord(['quanbu', 'record']) },
                sourceTypeList: [
                    { id: null, method: this.getresult, name: getWord(['quanbu', 'record']) },
                    { id: 1, method: this.getresult, name: getWord(['recharge2', 'record']) },
                    { id: 2, method: this.getresult, name: getWord(['withdraw3', 'record']) },
                    { id: 3, method: this.getresult, name: "QY-"+getWord('pc28dandan')+getWord('betting')+"/"+getWord('income') },
                    { id: 5, method: this.getresult, name: "QY-"+getWord('jingdiancai')+getWord('betting')+"/"+getWord('income') },
                    { id: 6, method: this.getresult, name: getWord('gaopincai')+getWord('betting')+"/"+getWord('income')},
                    { id: 7, method: this.getresult, name: getWord('asia_games')+getWord('betting')+"/"+getWord('income')},
                    { id: 4, method: this.getresult, name: getWord(['transfer', 'record']) }
                ],
                sheetVisible: false,
                value1: null,
                loading_data2: true,
                page: 1,
                pageSize: 20,
                totalPage: "",
                formateDatestartTime: "",
                formateDateendTime: "",
                formateDatestartTimeStamp:null,
                formateDateendTimeStamp:null,
            };
        },
        filters: {
            getcolor(val) {
                if (val < 0) {
                    return "red-text";
                } else {
                    return "green-text";
                }
            },
            sourceType(st) {},
            timestampToTime(timestamp) {
                var date = new Date(timestamp * 1000);
                var Y = date.getFullYear() + "-";
                var M =
                    (date.getMonth() + 1 < 10 ?
                        "0" + (date.getMonth() + 1) :
                        date.getMonth() + 1) + "-";
                var D = date.getDate() + " ";
                var h = date.getHours() + ":";
                var m = date.getMinutes() + ":";
                var s = date.getSeconds();
                return Y + M + D + h + m + s;
            }
        },
        methods: {
            left_click() {
                let hisNum = parseInt(window.history.length);
                if (hisNum == 1) {
                    this.$router.push("/home");
                } else {
                    this.$router.go(-1);
                }
            },

            sourceTypeName(id) {
                return this.sourceTypeList["id"]["name"];
            },
            loadMore() {},
            formateDate: function(date) {
                var Y = date.getFullYear() + "-";
                var M =
                    (date.getMonth() + 1 < 10 ?
                        "0" + (date.getMonth() + 1) :
                        date.getMonth() + 1) + "-";
                var D =
                    date.getDate() + 1 < 10 ?
                    "0" + (date.getDate() + 1) :
                    date.getDate() + 1;
                return Y + M + D;
            },
            getbank() {},
            showStart: function() {
                this.$refs["startPicker"].open();
            },
            showEnd: function() {
                this.$refs["endPicker"].open();
            },
            handleConfirmStart: function(date) {
                if (new Date(date).getTime()>this.formateDateendTimeStamp) {

                }else {
                    var year = this.startTime.getFullYear();
                    var month = this.startTime.getMonth() + 1;
                    var day = this.startTime.getDate();                                
                    this.formateDatestartTime = year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
                    this.formateDatestartTimeStamp= new Date(date).getTime();
                }
            },
            handleConfirmEnd: function(date) {
                if (new Date(date).getTime()<this.formateDatestartTimeStamp) {

                }else {
                    var year = this.endTime.getFullYear();
                    var month = this.endTime.getMonth() + 1;
                    var day = this.endTime.getDate();                                
                    this.formateDateendTime = year + "-" + this.formatTen(month) + "-" + this.formatTen(day);
                    this.formateDateendTimeStamp= new Date(date).getTime();
                }
            },
            showSourceType: function() {
                this.sheetVisible = true;
            },
            getresult: function(val, inx) {
                this.sourceType = val;
            },

            search() {
                this.list = [];
                this.page = 1;
                this.pageSize = 20;
                this.loadData();
            },
            loadData: function() {

                var time0 = new Date(
                    new Date(new Date(this.startTime).toLocaleDateString()).getTime()
                );
                let start = new Date(time0).getTime(new Date(time0));
                start = parseInt(start.toString() / 1000);
                
                time0 = new Date(
                    new Date(new Date(this.endTime).toLocaleDateString()).getTime() +
                    24 * 60 * 60 * 1000 -
                    1
                );
                let end = new Date(time0).getTime(new Date(time0));
                end = parseInt(end.toString() / 1000);
                var temptype = null;
                if (this.sourceType["id"] == null) {
                    temptype = this.testSourcetype;
                } else if (this.sourceType["id"] == "1") {
                    temptype = this.inputSourcetype;
                } else if (this.sourceType["id"] == "2") {
                    temptype = this.outputSourcetype;
                } else if (this.sourceType["id"] == "3") {
                    temptype = this.ddSourcetype;
                } else if (this.sourceType["id"] == "4") {
                    temptype = this.transferRecord;
                } else if (this.sourceType["id"] == "5") {
                    temptype = this.dtClassic;
                }else if (this.sourceType["id"] == "6") {
                    temptype = this.highFrequency;
                }else if (this.sourceType["id"] == "7") {
                    temptype = this.asia;
                }
                getCapital(start, end, temptype, this.page, this.pageSize).then(
                    result => {
                        if (result.data.code == "SUCCESS") {
                            for (var i = 0; i <= result.data.result.list.length - 1; i++) {
                                this.list.push(result.data.result.list[i]);
                            }
                            this.totalPage = result.data.result.totalPage;

                            if (this.list.length===0) {
                                this.list.push({
                                    errorMsg: getWord('no_result_in_this_range')
                                })
                            }
                        } else {
                            app.Mint.Toast(result.data.msg);
                        }
                    }
                );
                this.loading_data2 = false;
            },

            loadMore_data() {
                if (this.page >= this.totalPage) {
                    return false;
                } else {
                    this.page++;
                    this.loadData();
                }
            },
            formatTen(num) {
                return num > 9 ? num + "" : "0" + num;
            },

            getDay(num, str) {
                var today = new Date();
                var oYear = today.getFullYear();
                var oMoth = (today.getMonth() + 1).toString();
                var oDay = today.getDate().toString();;
                if (oMoth.length <= 1) oMoth = "0" + oMoth;
                if (oDay.length <= 1) oDay = "0" + oDay;
                return oYear + str + oMoth + str + oDay;
            }
        },
        mounted() {
            this.formateDatestartTime = this.formateDateendTime = this.getDay(-1, "-");
            this.formateDatestartTimeStamp = this.formateDateendTimeStamp = new Date(this.formateDatestartTime).getTime();
            
        },
        created(){
            
        },
    };
</script>
<template>
    <div id="personal" :class="_TEMPLATE">
        <Header
            :mainTitle="header.mainTitle"
            :backButton=false
        />
        <div class="main">
            <h4>{{ getWord('select_range') }}</h4>
            <div class="filter">
                <fieldset @click="showSourceType">
                    <label>{{ getWord('select_category') }}</label>
                    <span class="select">{{sourceType['name']}}</span>
                    <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                </fieldset>
                <fieldset @click="showStart">
                    <label>{{ getWord('start_from') }}</label>
                    <span class="select">{{formateDatestartTime}}</span>
                    <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                </fieldset>
                <fieldset @click="showEnd">
                    <label>{{ getWord('end_in') }}</label>
                    <span class="select">{{formateDateendTime}}</span>
                    <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                </fieldset>                
                <div class="submit">
                    <mt-button @click="search()" type="primary">{{ getWord('inquire') }}</mt-button>
                </div>
            </div>
            <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
                <table v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data2" infinite-scroll-distance="50" v-if="list">
                    <tr>
                        <th width="25%">{{ getWord('date') }}</th>
                        <th width="25%">{{ getWord(['transaction', 'category']) }}</th>
                        <th width="25%">{{ getWord(['transaction', 'quota']) }}</th>
                        <th width="25%">{{ getWord('quota_remain') }}</th>
                    </tr>
                    <tr v-for="(item,i) in list" :key="i" v-if="!list[0].errorMsg">
                        <td>{{new Date(item.create_at*1000).toLocaleDateString().replace(/\//g, "-") + "\r\n" + new Date(item.create_at*1000).toTimeString().substr(0, 8)}}</td>
                        <td>{{item.source_type}}</td>
                        <td :class="item.change_money|getcolor">{{item.change_money}}</td>
                        <td>{{item.after_money}}</td>
                    </tr>
                    <tr v-else>
                        <td colspan="4">{{item.errorMsg}}</td>
                    </tr>
                </table>
            </template>
            <template v-if="_TEMPLATE==='template-3'">
                <ul v-infinite-scroll="loadMore_data" infinite-scroll-disabled="loading_data2" infinite-scroll-distance="50" v-if="list">
                    <li v-for="(item,i) in list" :key="i" v-if="!list[0].errorMsg">
                        <h4>
                            {{item.source_type}}
                            <span>
                                {{new Date(item.create_at*1000).toLocaleDateString().replace(/\//g, "-") + "\r\n" + new Date(item.create_at*1000).toTimeString().substr(0, 8)}}
                            </span>
                        </h4>
                        <h5 :class="item.change_money|getcolor">
                            {{item.change_money}}
                            <span>
                                {{ getWord('balance') }}{{item.after_money}}
                            </span>                            
                        </h5>
                    </li>
                </ul>
            </template>
        </div>
        <mt-actionsheet :actions="sourceTypeList" v-model="sheetVisible"></mt-actionsheet>
        <mt-datetime-picker ref="startPicker" type="date" v-model="startTime" :year-format="'{value} '+getWord('year')" :month-format="'{value} '+getWord('month')" :date-format="'{value} '+getWord('day')" @confirm="handleConfirmStart"></mt-datetime-picker>
        <mt-datetime-picker ref="endPicker" type="date" v-model="endTime" :year-format="'{value} '+getWord('year')" :month-format="'{value} '+getWord('month')" :date-format="'{value} '+getWord('day')" @confirm="handleConfirmEnd"></mt-datetime-picker>
    </div>
</template>
<style scoped lang='scss' type="text/css">
#personal {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.template-3 {

        .main {

            > h4 {
                display: none;
            }

            .filter {
                background-color: #ffffff;

                fieldset {
                    border-top: 0;
                    font-size: .28rem;
                    border-bottom: 1px solid #B0B0B0;

                    label {
                        color: #B0B0B0;
                    }

                    .select {
                        color: #5F646E;
                    }

                    .rightArrow {
                        opacity: .4;
                        transform: rotate(0);
                    }
                }

                .submit {

                    button {
                        background-color: #EC2829;
                        height: auto;
                        padding: .2rem;

                        label {
                            font-size: .34rem;
                        }
                    }
                }
            }

            > ul {

                li {
                    display: flex;
                    justify-content: space-between;
                    padding: .25rem;

                    &:nth-child(even) {
                        background-color: #ffffff;
                    }

                    span {
                        display: block;
                    }

                    h4 {
                        color: #5F646E;
                        font-size: .3rem;
                        font-weight: normal;
                        line-height: .5rem;

                        span {
                            color: #B0B0B0;
                            font-size: .28rem;
                        }
                    }

                    h5 {
                        font-size: .36rem;
                        font-weight: normal;
                        line-height: .5rem;
                        text-align: right;

                        span {
                            color: #B0B0B0;
                            font-size: .28rem;   
                        }

                        &.red-text {
                            color: red;
                        }

                        &.green-text {
                            color: green;
                        }
                    }
                }
            }
        }

        .mint-datetime {

            /deep/ .mint-datetime-picker {

                .picker-items {

                    .picker-item {
                        border-top:1px solid #eeeeee;
                    }

                    .picker-center-highlight {
                        display: none;
                    }
                }

                .picker-toolbar {
                    display: flex;
                    height: auto;

                    span {
                        font-size: .36rem;
                        float: none;
                        line-height: 1rem;

                        &.mint-datetime-cancel {
                            color: #5F646E;                        
                            text-align: left;
                            padding-left: .5rem;
                        }

                        &.mint-datetime-confirm {
                            text-align: right;
                            padding-right: .5rem;
                        }
                    }
                }
            }
        }
    }

    .main {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        background-color: #EFEFEF;

        > h4 {
            font-size: .3rem;
            color: #999999;
            margin-left: .4rem;
            padding-top: .4rem;
            padding-bottom: .2rem;
        }

        .filter {
            
            fieldset {
                border:0;
                background-color: #ffffff;
                border-top: 1px solid #cccccc;
                font-size: .3rem;
                height: .9rem;
                line-height: .9rem;
                position: relative;

                &:first-child {
                    border-top:0;
                }

                label {
                    color: #999999;
                    margin-left: .4rem;
                    width: 25%;
                    display: inline-block;
                    white-space: nowrap;
                }

                .select {

                }

                .rightArrow {
                    position: absolute;
                    right: 0.45rem;
                    top: 0.3rem;
                    width: 0.18rem;
                    height: 0.33rem;
                    filter: grayscale(100%);
                    opacity: .7;
                    transform: rotate(90deg);
                }
            }

            div.submit {
                padding:0 .4rem;
                margin:.4rem 0;

                button {
                    display: block;
                    width: 100%
                }
            }
        }

        table {
            border-collapse: collapse;
            background-color: white;
            font-size: 0.3rem;
            text-align: center;
            width: 100%;

            .red-text {
                color: red;
            }

            .green-text {
                color: green;
            }

            th {    
                color: rgba(71, 150, 252, 1);
                border-left: 1px solid rgba(187, 187, 187, 1);
                padding: .25rem 0;
                
                &:first-child {
                    border-left:0;
                }
            }

            td {
                border-left: 1px solid rgba(187, 187, 187, 1);
                border-top: 1px solid rgba(187, 187, 187, 1);
                font-size: 0.24rem;
                color: rgba(102, 102, 102, 1);
                padding: .25rem 0;
                
                &:first-child {
                    border-left:0;
                }
            }
        }
    }
}
</style>
